import axios from 'axios';
import contentData from './content.json';

class OrderService {
    constructor() {
        this.apiToken = 'ZCWJcIuKQY1ZGEX06y5pLPTYsI1zeHqzVPpTjawprVKcSu3jsc4B9U6Bvr6n';
        this.baseUrl = 'https://supersale.pro/api/api/orders';
    }

    async getUserIp() {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            //console.error('Error fetching user IP address:', error);
            throw error;
        }
    }

    async createOrder(userPhone, userName, comment) {
        const senderID = window.senderID;
        console.log(senderID);

        const userIp = await this.getUserIp();

        const headers = {
            'Content-Type': 'application/json',
             'X-Disable-RateLimit': true
        };

        const body = {
            api_token: this.apiToken,
            order: {
                phone: userPhone,
                name: userName,
                comment: comment,
                link: window.location.hostname,
                source_id: senderID,
                delivery_type_id: 0,
                sender_id: 1,
                products: [
                    {
                        offer_id: contentData.id,
                        price: 0,
                        quantity: 1
                    }
                ]
            }
        };

        try {
            const response = await axios.post(this.baseUrl, body, { headers });
            return response.data;
        } catch (error) {
            //console.error('Error creating order:', error);
            throw error;
        }
    }
}

export default new OrderService();
